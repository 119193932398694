/* @import '~normalize.css'; */
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiCardHeader-title {
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}

/* .MuiTextField-root {
  margin-top: 0.5rem !important;
} */

.MuiInput-underline::after {
  border-bottom: 1px solid rgba(247, 250, 252, 0.5) !important;
}

.MuiSelect-select {
  padding: 0.7rem 2rem 0.7rem 1rem !important;
}

.MuiFormControlLabel-labelPlacementStart {
  margin-left: 0rem !important;
}

.MuiFormControlLabel-labelPlacementStart > .MuiFormControlLabel-label {
  width: 100% !important;
}

.MuiTableCell-stickyHeader {
  background: #ffffff !important;
  border-bottom: 1px solid #ddd !important;
}

div.MuiTableContainer-root > table > thead > tr > th > span {
  font-weight: 500;
}
